import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import Loader from '../Pages/Loader/Loader';

const tele = window.Telegram.WebApp;

export const UserContext = createContext();

const UserWrapper = ({ children, setError }) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);


    useEffect(() => {
        const checkUser = async () => {
            let telegram_id = tele.initDataUnsafe?.user?.id || "default2";
            telegram_id = `bt${telegram_id}`
            const telegram_username = tele.initDataUnsafe?.user?.username || tele.initDataUnsafe?.user?.first_name || 'default2';
            const urlParams = new URLSearchParams(window.location.search);
            const ref = urlParams.get('ref') ? urlParams.get('ref') : 'NA';

            setLoading(true);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_HOST_URL}/api/user/create-user`, {
                    telegram_id,
                    ref,
                    telegram_username,
                }, { withCredentials: true });

                const data = response.data;
                if (response.status === 201 || response.status === 200) {
                    setUser(data.user);
                } else {
                    console.error('Error creating/checking user:', data.message);
                    setError(true); // Set error state in App.js
                }
            } catch (error) {
                console.error('Server error:', error);
                setError(true); // Set error state in App.js
            }

            setLoading(false);
        };

        checkUser();
    }, [setError]);

    if (loading) {
        return <Loader />;
    }

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserWrapper;
