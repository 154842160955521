// App.js
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { lazy, Suspense, useEffect, useState, useContext } from 'react';
import BackButton from './Components/TelegramChecks/BackButton';
import DailyBonus from './Pages/DailyBonus/DailyBonus';
import Boost from './Pages/Boost/Boost';
import UserWrapper from './ContextAPI/UserWrapper';
import Loader from './Pages/Loader/Loader';
import Maintenance from './Pages/Maintenance/Maintenance'; // Import the Maintenance component
import { EnvironmentContext } from './ContextAPI/EnvironmentContext.js';

const Home = lazy(() => import('./Pages/Home/Home'));
const Tasks = lazy(() => import('./Pages/Tasks/Tasks'));
const Wallet = lazy(() => import('./Pages/Wallet/Wallet'));
const Friends = lazy(() => import('./Pages/Friends/Friends'));

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { isTelegram, isMobile } = useContext(EnvironmentContext);

  useEffect(() => {
    const tele = window.Telegram.WebApp;
    tele.ready();

    const loadAssets = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 2000));
        setLoading(false);
      } catch (error) {
        console.error('Error loading assets:', error);
        setLoading(false);
        setError(true);
      }
    };

    loadAssets();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Maintenance />; // Render maintenance page on error
  }

  if (!isTelegram || !isMobile) {
    return <div className='no-desktop-view'><p>Please open the app in Telegram on a mobile device.</p></div>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <BackButton />
          <UserWrapper setError={setError}>
            <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/tasks' exact element={<Tasks />} />
              <Route path='/wallet' exact element={<Wallet />} />
              <Route path='/friends' exact element={<Friends />} />
              <Route path='/daily-bonus' exact element={<DailyBonus />} />
              <Route path='/boost' exact element={<Boost />} />
            </Routes>
          </UserWrapper>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
