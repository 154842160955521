export const dailyClaimBonus = [
    { day: 1, bonus: 500 },
    { day: 2, bonus: 1000 },
    { day: 3, bonus: 2500 },
    { day: 4, bonus: 4500 },
    { day: 5, bonus: 10000 },
    { day: 6, bonus: 15000 },
    { day: 7, bonus: 30000 },
    { day: 8, bonus: 65000 },
    { day: 9, bonus: 100000 },
    { day: 10, bonus: 250000 }
];


export const storageLevels = [
    { level: 1, time: 3, unit: "hours", cost: 0 },
    { level: 2, time: 6, unit: "hours", cost: 20000 },
    { level: 3, time: 12, unit: "hours", cost: 40000 },
    { level: 4, time: 24, unit: "hours", cost: 80000 },
    { level: 5, time: 48, unit: "hours", cost: 160000 },
    { level: 6, time: 96, unit: "hours", cost: 320000 },
    { level: 7, time: 192, unit: "hours", cost: 640000 },
    { level: 8, time: 384, unit: "hours", cost: 1280000 },
    { level: 9, time: 768, unit: "hours", cost: 2560000 },
    { level: 10, time: 1536, unit: "hours", cost: 5120000 }
];


export const feedLevels = [
    { level: 1, speed: 250, cost: 0 },
    { level: 2, speed: 375, cost: 20000 },
    { level: 3, speed: 600, cost: 40000 },
    { level: 4, speed: 850, cost: 80000 },
    { level: 5, speed: 1300, cost: 160000 },
    { level: 6, speed: 2000, cost: 320000 },
    { level: 7, speed: 3000, cost: 640000 },
    { level: 8, speed: 4300, cost: 1280000 },
    { level: 9, speed: 6500, cost: 2560000 },
    { level: 10, speed: 10000, cost: 5120000 }
];



export const userLevel = [
    { level: 1, name: "Starfish", min_balance: 0, max_balance: 999999, bonus: 0 },
    { level: 2, name: "Clownfish", min_balance: 1000000, max_balance: 4999999, bonus: 10000 },
    { level: 3, name: "Seahorse", min_balance: 5000000, max_balance: 9999999, bonus: 15000 },
    { level: 4, name: "Jellyfish", min_balance: 10000000, max_balance: 49999999, bonus: 20000 },
    { level: 5, name: "Turtle", min_balance: 50000000, max_balance: 99999999, bonus: 25000 },
    { level: 6, name: "Dolphin", min_balance: 100000000, max_balance: 499999999, bonus: 30000 },
    { level: 7, name: "Shark", min_balance: 500000000, max_balance: 999999999, bonus: 35000 },
    { level: 8, name: "Whale", min_balance: 1000000000, max_balance: 4999999999, bonus: 40000 },
    { level: 9, name: "Kraken", min_balance: 5000000000, max_balance: 9999999999, bonus: 45000 },
    { level: 10, name: "Legendary Leviathan", min_balance: 10000000000, max_balance: Infinity, bonus: 50000 }
];




export const inviteBonus = 1000;

export const formatBalance = (balance) => {
    if (balance >= 1e12) return (balance / 1e12).toFixed(1) + 'T';
    if (balance >= 1e9) return (balance / 1e9).toFixed(1) + 'B';
    if (balance >= 1e6) return (balance / 1e6).toFixed(1) + 'M';
    if (balance >= 1e3) return (balance / 1e3).toFixed(1) + 'K';
    return balance.toFixed(2);
};