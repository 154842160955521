import React from 'react';
import './Loader.css';

const Loader = () => {
    return (
        <div class="loader">
            <div class="loading-bar-container">
                <div class="loading-bar"></div>
            </div>
        </div>


    );
}

export default Loader;
