import React from 'react';
import './BoostCard.css';

const BoostCard = ({ task, onClose, userData, onUpgrade }) => {
    return (
        <div className={task ? 'task-card show' : 'task-card'}>
            <div className='task-card-content'>
                <button className='task-card-close' onClick={onClose}>✖</button>
                <div className='level-label'>
                    <h1>Level {userData.nextLevel}</h1>
                </div>
                <h4>Upgrade your {userData.type} to Level {userData.nextLevel}</h4>
                {userData.type === 'Mining Speed' ? <p className='open-task'>Mining Speed {userData.value}</p> : <p className='open-task'>Claim between {userData.value}</p>}
                <div className='task-card-rewards'>
                    <img src='/Asserts/invite-coin.png' alt='Reward' />
                    <p>-{userData.cost}</p>
                </div>
                <button className='check-task' onClick={onUpgrade}>Upgrade</button>
            </div>
        </div>
    );
};

export default BoostCard;
