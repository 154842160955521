import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import axios from 'axios';
import "./Boost.css";
import { UserContext } from '../../ContextAPI/UserWrapper';
import { feedLevels, formatBalance, storageLevels } from '../../Utils/global';
import toast, { Toaster } from 'react-hot-toast';
import BoostCard from '../../Components/BoostCard/BoostCard';

const Boost = () => {
    const { user, setUser } = useContext(UserContext);
    const [totalBalance, setTotalBalance] = useState(0);
    const [loading, setLoading] = useState(false);
    const [boostCardData, setBoostCardData] = useState(null);

    useEffect(() => {
        if (user) {
            setTotalBalance(parseFloat(user.available_balance.$numberDecimal));
        }
    }, [user]);

    const handleBoostStorage = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_HOST_URL}/api/boost/boost-storage`, {
                telegram_id: user.telegram_id // Replace with actual user identifier
            });
            setLoading(false);
            setUser(response.data.user);
            toast.success('Successfully Upgraded!');
            setBoostCardData(null)
        } catch (error) {
            setLoading(false);
            console.error('Error boosting storage:', error);
            toast.error(error.response.data.message);
        }
    };

    const handleBoostMiningSpeed = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_HOST_URL}/api/boost/boost-mining-speed-1`, {
                telegram_id: user.telegram_id // Replace with actual user identifier
            });
            setLoading(false);
            setUser(response.data.user);
            toast.success('Successfully Upgraded!');
            setBoostCardData(null)
        } catch (error) {
            setLoading(false);
            console.error('Error boosting mining speed:', error);
            toast.error(error.response.data.message);
        }
    };

    const openBoostCard = (type, nextLevel, value, cost, upgradeHandler) => {
        setBoostCardData({ type, nextLevel, value, cost, upgradeHandler });
    };

    return (
        <div className='boost-section'>
            <Navbar />
            <Toaster />
            <div className='boost-container'>
                <div className='home-avl-bal'>
                    <p>Total Balance</p>
                    <div className='balance-pair'>
                        <img src='/Asserts/invite-coin.png' alt='Total Balance' />
                        <p>{formatBalance(totalBalance)}</p>
                    </div>
                </div>
                <div className='boots-items'>
                    <div className='boost-item'>
                        <img src='/Asserts/storage.png' alt='Storage Level 1' />
                        <div className='boost-content'>
                            <h3>Storage Level {user.storage.level}/{storageLevels.length}</h3>
                            <p>Increase the time between Claims</p>
                        </div>
                        {!(user.storage.level === storageLevels.length) &&
                            <button onClick={() => openBoostCard(
                                'Storage',
                                user.storage.level + 1,
                                `${storageLevels[user.storage.level].time}H`,
                                storageLevels[user.storage.level].cost,
                                handleBoostStorage
                            )}
                                disabled={loading}>
                                Boost Lv{user.storage.level + 1}
                            </button>
                        }
                    </div>
                    <div className='boost-item'>
                        <img src='/Asserts/speed.png' alt='RAM Level 1' />
                        <div className='boost-content'>
                            <h3>Speed Level {user.mining_speed.speed_1.level}/{feedLevels.length}</h3>
                            <p>Increase the Mining Speed</p>
                        </div>
                        {!(user.mining_speed.speed_1.level === feedLevels.length) &&
                            <button onClick={() => openBoostCard(
                                'Mining Speed',
                                user.mining_speed.speed_1.level + 1,
                                `${feedLevels[user.mining_speed.speed_1.level].speed}/H`,
                                feedLevels[user.mining_speed.speed_1.level].cost,
                                handleBoostMiningSpeed,
                            )}
                                disabled={loading}>
                                Boost Lv{user.mining_speed.speed_1.level + 1}
                            </button>
                        }
                    </div>
                </div>
            </div>
            {boostCardData &&
                <BoostCard
                    task={true}
                    onClose={() => setBoostCardData(null)}
                    userData={boostCardData}
                    onUpgrade={boostCardData.upgradeHandler}
                />
            }
        </div>
    );
};

export default Boost;
