import React from 'react'
import { Link } from 'react-router-dom';
import "./Navbar.css";

export default function Navbar({ currentLocation }) {
    return (
        <nav>
            <div className='nav-wrapper'>
                <Link to="/" className={currentLocation === 'mine' && 'tap'}><i class="fa-solid fa-gem"></i><p>MINE</p></Link>
                <Link to="/friends" className={currentLocation === 'friends' && 'tap'}><i class="fa-solid fa-user-group"></i><p>FRIENDS</p></Link>
                <Link to="/tasks" className={currentLocation === 'tasks' && 'tap'}><i class="fa-solid fa-coins"></i><p>TASKS</p></Link>
                <Link to="/wallet" className={currentLocation === 'wallet' && 'tap'}><i class="fa-solid fa-wallet"></i><p>WALLET</p></Link>
            </div>
        </nav>
    )
}
