import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { UserContext } from '../../ContextAPI/UserWrapper';
import "./DailyBonus.css";
import { dailyClaimBonus, formatBalance } from '../../Utils/global';

export default function DailyBonus() {
    const { user, setUser } = useContext(UserContext);
    const [claimStatus, setClaimStatus] = useState({
        current_day: 0,
        claimed: false,
        last_claim: null,
    });
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        if (user) {
            setClaimStatus(user.daily_claim_bonus);
        }

        // Fetch the current date from the API
        const fetchCurrentDate = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_HOST_URL}/api/get-current-timestamp`);
                setCurrentDate(new Date(response.data.timestamp));
            } catch (error) {
                console.error('Error fetching current date:', error);
            }
        };

        fetchCurrentDate();
    }, [user]);

    const handleClaim = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_HOST_URL}/api/daily/daily-claim-bonus`, {
                telegram_id: user.telegram_id
            });

            // Immediately update the local state
            const updatedUser = response.data.user;
            setClaimStatus(updatedUser.daily_claim_bonus);
            setUser(updatedUser);

            toast.success(response.data.message);
        } catch (error) {
            console.error('Error claiming daily bonus:', error);
            toast.error(error.response?.data?.message || 'Failed to claim bonus');
        }
    };

    const getClassForDay = (day) => {

        if (day < claimStatus.current_day) {
            return 'daily-collect-button selected';
        } else if (day === claimStatus.current_day + 1 && !isClaimDisabled()) {
            return 'daily-collect-button current';
        } else if (day === claimStatus.current_day && claimStatus.claimed) {
            return 'daily-collect-button selected';
        } else if (day === 1 && claimStatus.current_day === 0 && !claimStatus.claimed) {
            return 'daily-collect-button current';
        } else {
            return 'daily-collect-button';
        }
    };


    const resetDays = (day) => {

        if (day === 1) {
            return 'daily-collect-button current';
        }
        else {
            return 'daily-collect-button';
        }
    }

    const getStartOfDay = (date) => {
        const startOfDay = new Date(date);
        startOfDay.setHours(0, 0, 0, 0);
        return startOfDay;
    };

    const isClaimDisabled = () => {
        const startOfCurrentDay = getStartOfDay(currentDate);
        const startOfLastClaimDay = getStartOfDay(new Date(user.daily_claim_bonus.last_claim));

        if (!claimStatus.claimed) {
            return false
        }
        // If the day has changed, reset the claimed flag
        if (startOfCurrentDay > startOfLastClaimDay) {
            return false;
        }

        return true;
    };

    return (
        <div className='daily-bonus-section'>
            <Navbar />
            <Toaster />
            <div className='daily-bonus-container'>
                <div className='daily-bonus-title-container'>
                    <img src='/Asserts/daily.png' alt='Daily Bonus' />
                    <h1>Daily Bonus</h1>
                    <p>Collect your Daily Bonus for logging into the game daily</p>
                </div>
                <div className='daily-collect-button-container'>
                    {dailyClaimBonus.map((bonus, index) => (
                        <div key={index} className={(Math.floor((getStartOfDay(currentDate) - getStartOfDay(new Date(user.daily_claim_bonus.last_claim))) / (1000 * 60 * 60 * 24)) > 1 || user.daily_claim_bonus.current_day >= 10) ? resetDays(bonus.day) : getClassForDay(bonus.day)}>
                            <p>Day {bonus.day}</p>
                            <img src='/Asserts/invite-coin.png' alt={`Day ${bonus.day} bonus`} />
                            <h3>+{formatBalance(bonus.bonus)}</h3>
                        </div>
                    ))}
                </div>
                <div className='daily-claim-btn-container'>
                    <button
                        className={`daily-claim-btn ${isClaimDisabled() ? 'disabled' : ''}`}
                        onClick={handleClaim}
                        disabled={isClaimDisabled()}
                    >
                        {isClaimDisabled() ? 'Come back tomorrow' : 'Claim today\'s rewards'}
                    </button>
                </div>
            </div>
        </div >
    );
}
