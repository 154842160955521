import React, { createContext, useEffect, useState } from 'react';

export const EnvironmentContext = createContext();

const EnvironmentProvider = ({ children }) => {
  const [isTelegram, setIsTelegram] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const tele = window.Telegram.WebApp;
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (tele) {
      setIsTelegram(true);
    } else {
      setIsTelegram(false);
    }

    // Check if the device is a mobile device
    if (!/windows phone/i.test(userAgent) || /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  return (
    <EnvironmentContext.Provider value={{ isTelegram, isMobile }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentProvider;
